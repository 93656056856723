import styled, {createGlobalStyle, css} from "styled-components/macro";
import reset from 'styled-reset'
import {baseThemeStyled} from "./BaseTheme.styled";
import Check from "../images/check.png"
import {Link} from "react-router-dom";

export const GlobalStyle = createGlobalStyle`
  ${reset}
  
  body {
    font-family: ${baseThemeStyled.fontsFamily.Lato};
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    overflow-x: hidden;
  }

  li {
    margin: 0;
    padding: 0;
  }

  strong {
    font-weight: 700;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  img {
    max-width: 100%;
  }

  input {
    outline: none;
    border: none;
  }

  input::placeholder {
    font-size: 14px;
    line-height: 16px;
    color: #000;
  }
  
  button{
    cursor: pointer;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease all;
    border-radius: 3px;
  }
  
`

export const Section = styled.section.attrs({
  "data-id": "section"
})<{bgColor? : string}>`
  position: relative;
  background-color: ${props => props.bgColor ? props.bgColor : "#fff"};
`


export const Container = styled.div.attrs({
  "data-id": "container"
})`
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1128px;
  padding-left: 18px;
  padding-right: 18px;
  @media ${baseThemeStyled.breakpoints.large} {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  @media ${baseThemeStyled.breakpoints.small} {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
`

export const MainArea = styled.main.attrs({
  "data-id": "main-area"
})`
  position: relative;
`

export const Button = styled.button.attrs({
  "data-id": "button"
})`
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease all;
  border-radius: 3px;
`

export const ButtonRed = styled(Button).attrs({
  "data-id": "button-red"
})`
  background-color: ${baseThemeStyled.colors.link};
  &:hover, &:active, &:focus{
    background-color: #B1092C;
  }
`

export const BackLink = styled(Link)`
  background-color: ${baseThemeStyled.colors.link};
  padding: 12px 22px;
  font: 700 13px/16px ${baseThemeStyled.fontsFamily.Lato};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration-line: none;
  max-width: 300px;
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    max-width: 100%;
  }
  &:hover, &:active, &:focus{
    background-color: #B1092C;
    color: #fff;
  }
`

export const Page = styled(Section).attrs({
  "data-id": "page"
})<{style?: any}>`
  padding: 27px 0 50px 0;
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    padding: 17px 0 25px 0;
  };
  ${props => {
    return props.style
}}
`

export const TitleArea = styled.div.attrs({
  "data-id": "title-area"
})<{line?: boolean }>`
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 30px;
  border-bottom: ${props => props.line ? "1px solid #8C9398" : "" };
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    margin-bottom: 6px;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    margin-bottom: 20px;
  }
`

export const Title = styled.h1.attrs({
  "data-id": "title"
})`
  color: #38454E;
  position: relative;
  font: 700 36px/43px ${baseThemeStyled.fontsFamily.Lato};
   @media ${baseThemeStyled.breakpoints.large} {
    font: 700 36px/43px ${baseThemeStyled.fontsFamily.Lato};
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    font: 700 32px/34px ${baseThemeStyled.fontsFamily.Lato};
  }
  @media ${baseThemeStyled.breakpoints.small} {
    font: 700 27px/32px ${baseThemeStyled.fontsFamily.Lato};
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    font: 700 24px/28px ${baseThemeStyled.fontsFamily.Lato};
  }
`

export const Checkbox = styled.div.attrs({
  "data-id": "checkbox"
})`
  margin: 0 -4px;
`

export const CheckboxLabel = styled.label.attrs({
  "data-id": "checkbox-label"
})`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const CheckboxInput = styled.input.attrs({
  "type": "checkbox",
  "data-id": "checkbox-input"
})`
  display: none;
`

export const CheckboxSquare = styled.span.attrs({
  "data-id": "checkbox-square"
})`
  display: inline-block;
  width: 20px;
  flex: 0 0 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #B6BABD;
  margin: 0 4px;
  ${CheckboxInput}:checked + & {
    background-color: #E4E9EE;
    border-color: #E4E9EE;
    background-image: url(${Check});
    background-position: center;
    background-repeat: no-repeat;
  }
`
export const CheckboxText = styled.p.attrs({
  "data-id": "checkbox-text"
})`
  margin: 0 4px;
  user-select: none;
  display: inline-block;
  color: #333333;
  font: 400 15px/130%  ${baseThemeStyled.fontsFamily.PT};
  position: relative;
  top: 1px;
   @media ${baseThemeStyled.breakpoints.large} {
     font: 400 13px/17px  ${baseThemeStyled.fontsFamily.PT};
   }
  @media ${baseThemeStyled.breakpoints.small} {
   font: 400 12px/13px  ${baseThemeStyled.fontsFamily.PT};
  }
`

export const CheckboxLink = styled(Link).attrs({
  "data-id": "checkbox-text"
})`
  color: #333333;
  text-decoration-line: none;
  border-bottom: 1px solid #333333;
`
