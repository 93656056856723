import styled from "styled-components";
import {Container, Section} from "../../assets/styles/Main.styled";
import {baseThemeStyled} from "../../assets/styles/BaseTheme.styled";
import {css} from "styled-components/macro";

export const ContainerTeam = styled(Container).attrs({
  "data-id": "container-team"
})`
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    padding: 0
  }
`

export const Team = styled.div.attrs({
  "data-id": "team"
})<{revers?: boolean}>`
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.21);
  margin-bottom: 12px;
  padding: 10px 50px 10px 10px;
  display: flex;
  ${props => {
    if(props.revers){
      return css `
        flex-direction: row-reverse;
        padding: 10px 10px 10px 30px;
        [data-id="team-info"]{
          margin-right: 20px;
          @media ${baseThemeStyled.breakpoints.large}{
            padding-left: 13px;
          }
          @media ${baseThemeStyled.breakpoints.extraSmall}{
            padding-left: 0px;
            margin-right: 0;
          }
        }
        @media ${baseThemeStyled.breakpoints.medium}{
          padding-left: 8px;
        }
      `
    }else{
      return css`
        @media ${baseThemeStyled.breakpoints.medium}{
          padding-right: 20px;
        }
      `
    }
  }};
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    flex-direction: column;
  };
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    padding: 10px 16px 20px 16px;
    border-radius: 0;
  }
  //align-items: center;
`

export const TeamImage = styled.div.attrs({
  "data-id": "team-image"
})`
  height: 177px;
  flex: 0 0 285px;
  border-radius: 3px;
  overflow: hidden;
  img{
    object-fit: cover;
    height: 100%;
  }
  @media ${baseThemeStyled.breakpoints.small}{
    height: 179px;
    flex: 0 0 188px;
    img{
      object-position: 20%;
      width: 100%
    }
  }
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    flex: 1 1 auto;
  }
`

export const TeamInfo = styled.div.attrs({
  "data-id": "team-info"
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 45px;
  padding-top: 18px;
  @media ${baseThemeStyled.breakpoints.large}{
    padding-left: 55px;
  }
  @media ${baseThemeStyled.breakpoints.medium}{
    padding-left: 34px;
  }
  @media ${baseThemeStyled.breakpoints.small}{
    padding-left: 18px;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    padding: 15px 0 10px 0;
  }
`

export const TeamTitle = styled.div.attrs({
  "data-id": "team-title"
})`
  margin-bottom: 27px;

  h2{
    color: #000000;
    font: 600 24px/26px ${baseThemeStyled.fontsFamily.Lato};
    border-bottom: 1px solid;
    display: inline-block;
    letter-spacing: -1px;
    @media ${baseThemeStyled.breakpoints.extraSmall}{
      letter-spacing: -1.5px;
    }
  }
  @media ${baseThemeStyled.breakpoints.medium}{
    margin-bottom: 26px;
  }
  @media ${baseThemeStyled.breakpoints.small}{
    margin-bottom: 18px;
  }

`

export const TeamText = styled.div.attrs({
  "data-id": "team-text"
})`
  color: #000000;
  font: 400 16px/22px ${baseThemeStyled.fontsFamily.Lato};
  //margin-bottom: 3px;
  span{
    color: #BD0A2F
  }
  @media ${baseThemeStyled.breakpoints.medium}{
    font: 400 14px/18px ${baseThemeStyled.fontsFamily.Lato};
    margin-bottom: 3px;
  }
`

export const TeamUl = styled.div.attrs({
  "data-id": "team-ul"
})`
  display: flex;
  flex-direction: column;
`

export const TeamLi = styled.div.attrs({
  "data-id": "team-li"
})`
  color: #000000;
  position: relative;
  padding-left: 27px;
  font: 400 16px/24px ${baseThemeStyled.fontsFamily.Lato};
  &:before{
    content: '';
    position: absolute;
    background-color: #BD0A2F;
    height: 3px;
    width: 18px;
    left: 0;
    top: calc(50% - 1px);
    @media ${baseThemeStyled.breakpoints.small}{
      width: 7px;
    }
    @media ${baseThemeStyled.breakpoints.extraSmall}{
      width: 18px;
    }
  }
  @media ${baseThemeStyled.breakpoints.medium}{
    font: 400 14px/18px ${baseThemeStyled.fontsFamily.Lato};
  }
  @media ${baseThemeStyled.breakpoints.small}{
    padding-left: 18px;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    padding-left: 27px;
  }
`

