import styled from "styled-components";
import {Button} from "../../../../assets/styles/Main.styled";
import {baseThemeStyled} from "../../../../assets/styles/BaseTheme.styled";
import {Link} from "react-router-dom";

export const MainTagEl = styled.div.attrs({
  "data-id": "main-tag-el"
})`
  position: relative;
  //z-index: 5;
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    display: none;
  }
`

export const MainTagButtonArea = styled.div.attrs({
  "data-id": "main-tag-button-area"
})`
  position: relative;
  display: flex;
  align-items: center;
`

export const MainTagButton = styled(Link).attrs({
  "data-id": "main-tag-button"
})`
  background: rgba(239, 239, 239, 0.8);
  box-shadow: 0px 32px 84px rgba(0, 0, 0, 0.21725);
  color: #3B4851;
  font: 700 12px/13px ${baseThemeStyled.fontsFamily.Lato};
  text-transform: uppercase;
  padding: 13px 19px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: 0.3s ease all;
  text-decoration-line: none;
  &:last-child{
    margin-right: 0;
  }
  &:hover{
    color: #000;
    background: rgba(239, 239, 239, 0.95);
  }
  @media ${baseThemeStyled.breakpoints.medium} {
      font: 700 11px/13px ${baseThemeStyled.fontsFamily.Lato};
    padding: 9px 19px;
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    &:last-child{
      display: none;
    }
  }
  @media ${baseThemeStyled.breakpoints.small} {
    &:nth-last-child(-n+2){
         display: none;
    }
  }
`
