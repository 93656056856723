import React, {useMemo} from 'react';
import {CardVacancyBigListEl, CatalogListEl, EmptyVacancies} from "./CardVacancyBig.styled";
import CardVacancyBig from "./CardVacancyBig";
import {IVacancie, IVacanciesState} from "../../redux/Vacancies/VacanciesSlice";
import {useTypedSelector} from "../../hooks/useTypedSelection";
import {Declination} from "../../utils/Helpers";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {propertyNamesToLowercase} from "../../utils/shared";
import Vacancie from "../Vacancie/Vacancie";
import Empty from "../../assets/images/empty.png"

const CardVacancyBigList = () => {
  const {vacancies, pagination, status}: IVacanciesState = useTypedSelector(
    (state) => state.vacancies
  );

  const vacanciesMemo = useMemo(()=>{
   return  vacancies.map((item: IVacancie, index: number) => {

      const vacancie: any = propertyNamesToLowercase(item);

      return <CardVacancyBig {...vacancie} key={index}/>;
    })
  },[vacancies])

  return (
    <CatalogListEl>
      {status === "idle" && vacancies?.length > 0 && (
        <SkeletonTheme baseColor="#DBDBDB" highlightColor="#ecebeb">
          <Skeleton
            className="selected-count-loading mt-10 mb-10"
            count={1}
          />
        </SkeletonTheme>
      )}

      {status === "success" && vacancies?.length > 0
       &&
        <div className="all-vacancy">
          Найдено {pagination?.SELECTED_COUNT}&nbsp;
          {Declination(pagination?.SELECTED_COUNT, [
            "вакансия",
            "вакансии",
            "вакансий",
          ])}{" "}
        </div>
      }

      <CardVacancyBigListEl>
        {status === "success" && vacancies?.length > 0 &&
          vacanciesMemo
        }

        {status === "success" && vacancies?.length === 0 && (
          <EmptyVacancies>
            <b>Результат поиска не найден</b>
            <img src={Empty} alt="не найден"/>
          </EmptyVacancies>

        )}

      </CardVacancyBigListEl>
    </CatalogListEl>
  );
};

export default CardVacancyBigList;
