import {StrictMode} from "react";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client";
import AppRouter from "./AppRouter";
import {InterviewContextProvider} from "./context/interview";
import {Provider} from "react-redux";
import store from "./redux/store";
import "./assets/fonts/lato/lato.css"
import "./assets/fonts/PTSans/stylesheet.css"
import "./assets/styles/main.sass"
import "./assets/styles/bootstrap.css"
// import 'bootstrap/dist/css/bootstrap.min.css';

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <InterviewContextProvider>
        <AppRouter/>
      </InterviewContextProvider>
    </BrowserRouter>
  </Provider>
);
