// import { createBrowserHistory } from "history";
import { configureStore,combineReducers } from "@reduxjs/toolkit";
import { GlobalApi } from "api/GlobalApi";
import vacancies from "./Vacancies/VacanciesSlice";
import filters from "./Filters/FiltersSlice";
import ui from "./UI/UISlice";

const combineReducer = combineReducers({
    [GlobalApi.reducerPath] : GlobalApi.reducer,
    vacancies,
    filters,
    ui
})

const store = configureStore({
    reducer: combineReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(GlobalApi.middleware),
  });

export type RootState = ReturnType<typeof combineReducer>;
export type AppDispatch = typeof store.dispatch
export default store;