import React, {FC} from 'react';
import {CardVacancyListCol, CardVacancyListEl, RedLine} from "./CardVacancy.styled";
import CardVacancy from "./CardVacancy";
import {Container} from "../../assets/styles/Main.styled";
import MainTitle from "../../pages/main-page/components/main-title/MainTitle";
import {Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import {EStatus} from "../../utils/types";
import {IVacancie} from "../../redux/Vacancies/VacanciesSlice";
import {propertyNamesToLowercase} from "../../utils/shared";

interface ICardVacancyList{
  status: EStatus,
  vacancies: IVacancie[]
}

const CardVacancyList: FC<ICardVacancyList> = ({status, vacancies}) => {
  return (
    <Container>

      <CardVacancyListEl>

        <Swiper
          pagination={{
            clickable: true
          }}
          autoHeight={true}
          modules={[Pagination]}
          spaceBetween={28}
          slidesPerView={3}
          className="swiper-with-pagination"
          breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 0
              },
              576: {
                slidesPerView: 2,
                spaceBetween: 15
              },
              770: {
                slidesPerView: 3,
                spaceBetween: 20
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30
              }
            }}
        >
          {vacancies.map((item, index)=>{
            const vacancie: any = propertyNamesToLowercase(item);
            return(
              <SwiperSlide key={index}>
                <CardVacancy {...vacancie}/>
              </SwiperSlide>
            )
          })}

        </Swiper>

      </CardVacancyListEl>
      {/*<RedLine/>*/}
    </Container>
  );
};

export default CardVacancyList;
