import React, {FC} from 'react';
// @ts-ignore
import {Helmet} from "react-helmet";

interface IHelmet {
  title: string,
  content: string
}
const HelmetComponent:FC<IHelmet> = ({content,title}) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta property="og:title" content={content}/>
    </Helmet>
  );
};

export default HelmetComponent;
