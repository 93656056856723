import React, {useContext, useEffect} from 'react'
// import MetaTags from 'react-meta-tags';
import HelmetComponent from "../../component/Helmet/Helmet";
// import A1 from "../assets/images/a1.jpg"
// import A2 from "../assets/images/a2.jpg"
// import A6 from "../assets/images/a6.jpg"
import FormVector from "../assets/images/form-vector.png"
import Face from "../assets/images/face.jpg"
import Slide from "../assets/images/slide.jpg"
import Form from "../../component/Form/Form"
import CardJob from "../../component/Card-job/Card-job"
import Search from "../../component/Search/Search"
import {Link} from "react-router-dom"
import Skeleton, {SkeletonTheme} from "react-loading-skeleton"
import 'react-loading-skeleton/dist/skeleton.css'
import {InterviewContext} from "../../context/interview"
import FormSuccess from "../../component/FormSuccess/FormSuccess"
import {useTypedSelector} from "../../hooks/useTypedSelection";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {getFilters, IFilter, IFiltersState} from "../../redux/Filters/FiltersSlice";
import MainBanner from "./components/main-banner/MainBanner";
import CardVacancyList from "../../component/card-vacancy/CardVacancyList";
import {Container, Section} from 'assets/styles/Main.styled';
import MainTitle from "./components/main-title/MainTitle";
import MainDignities from "./components/main-dignities/MainDignities";
import MainBrandSlider from "./components/main-brand-slider/MainBrandSlider";
import MainForm from "./components/main-form/MainForm";
import {getAllVacancies} from "../../redux/Vacancies/VacanciesSlice";
import {MainSection} from "./MainPage.styled";
import {SubTitle} from "./components/main-form/MainForm.styled";

const MainPage = () => {

  const {filters, status: statusFilter}: IFiltersState = useTypedSelector((state) => state.filters);

  const {vacancies, status: statusVacancies} = useTypedSelector(
    (state) => state.vacancies
  );

  const dispatch = useAppDispatch();

  const {
    success
  } = useContext(InterviewContext)



  useEffect(() => {
    dispatch(getFilters(""));
    dispatch(getAllVacancies(""));
  }, [])

  return (
    <div className="page">
      <HelmetComponent
        title="Приглашаем на работу в МТК Росберг!"
        content="Приглашаем на работу в МТК Росберг!"
      />

      <MainBanner/>


      <MainSection bgColor={"#F4F4F4"}>

        <Container>
          <MainTitle
            mb={25}
            lr_mb={25}
            md_mb={20}
            sm_mb={20}
            esm_mb={15}
            title="Найдите работу своей мечты!"
            link_title="Смотреть все вакансии"
            link_route="/vacancies"
          />
        </Container>

        <CardVacancyList
          status={statusVacancies}
          vacancies={vacancies.slice(0,5)}
        />

        <MainDignities/>

        <MainBrandSlider/>

      </MainSection>

      <MainForm/>

    </div>
  )
}

export default MainPage
