import React, {useEffect, useState} from 'react';
import {
  MainInputSearch,
  MainInputSearchIcon,
  MainSearchBody, MainSearchButton,
  MainSearchEl,
  MainSelect,
  MainSelectArea
} from "./MianSearch.styled";
import {IconSvg} from "../../../../utils/shared";
import {HeaderSearchInput} from "../../../../component/Header/Header.styled";
import Select, {components} from "react-select";
import {IMAGES} from "../../../../constants/Images";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../../hooks/useAppDispatch";
import {useTypedSelector} from "../../../../hooks/useTypedSelection";
import {getFilters} from "../../../../redux/Filters/FiltersSlice";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

const CustomOption = ({children, ...props}) => {
  return (
    <components.Option {...props} className="custom-option">
      <div className="custom-option__border">
        <div className="custom-option__item">
          {children}
        </div>
      </div>
    </components.Option>
  );
};

const CustomMenuList = ({children, ...props}) => {
  return (
    <components.MenuList {...props} className="custom-menu-list">
      {children}
    </components.MenuList>
  );
};

const CustomValueContainer = ({children, ...props}) => {
  return (
    <components.MenuList {...props}
                         className="custom-value-container">
      {children}
    </components.MenuList>
  );
};

const CustomControl = ({children, ...props}) => {
  return (
    <components.Control {...props}
                         className="custom-control">
      {children}
    </components.Control>
  );
};

const CustomInput= ({children, ...props}) => {
  return (
    <components.Input {...props} className="custom-input">
      {children}
    </components.Input>
  );
};

const CustomContainer = ({children, ...props}) => {
  return (
    <components.Container {...props} className="custom-container">
      {children}
    </components.Container>
  );
};

const MainSearch = () => {

  let navigate = useNavigate();

  const dispatch = useAppDispatch();

  const {status} = useTypedSelector((state) => state.filters);

  const [searchField, setSearchField] = useState('');

  //Все регионы
  const [cities, setCities] = useState([])
  //Регион который выбрали //от сюда берем код
  const [city, setCity] = useState("")


  const colourStyles = {
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#d7d8d9" : "#fff",
      ':active': {
        backgroundColor: state.isSelected ? "#B6BABD" : "#B6BABD"
      }
    }),
    singleValue: (...base) => ({
      color: "#000",
      position: "relative",
      // top: "14px",
      marginLeft: 0,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      width: "100%"
    }),
    control: (base, state) => ({
      ...base,
      height: "30px",
      minHeight: "30px",
      display: "flex",
      flexWrap: "no-wrap",
      maxHeight: "30px",
      paddingLeft: '10px',
      border: "0 !important",
      boxShadow: "0 !important",
      color: "#000",
      width: "100%",
      backgroundColor: 'transparent',
      "&:hover": {
        border: "0 !important"
      }
    })
  }

  // const DropdownIndicator = props => {
  //   return (
  //     <div className="select-icon" {...props}>
  //       <img
  //         src={IMAGES.SelectIcon}
  //         alt=""/>
  //       {/*<CaretDownIcon />*/}
  //     </div>
  //   );
  // };

  const Submit = (e) => {
    e.preventDefault();
    e.stopPropagation()
    navigate(`vacancies?FIND=${encodeURI(searchField)}&CITY[]=${encodeURI(city ? city : '')}`);
  }

  useEffect(() => {
    dispatch(getFilters("")).then((response) => {
      let newArraySection = []
      response.payload.forEach((filter) => {
        if (filter.type === "CITY") {
          filter.items.forEach((item) => {
            newArraySection.push(
              {
                value: item.value,
                label: item.value,
                code: item.id.toString(),
              }
            )
          })
        }
      })
      setCities(newArraySection)
    }).catch(e => console.log(e));
  }, []);

  const changeCity = (item) => {
    setCity(item.code)
  }

  // console.log(status)

  return (
    <MainSearchEl>
      <MainSearchBody onSubmit={Submit}>
        <MainInputSearch>
          <MainInputSearchIcon>
            {IconSvg.ui.search("#000")}
          </MainInputSearchIcon>
          <HeaderSearchInput
            onChange={e => setSearchField(e.target.value)}
            value={searchField}
            placeholder="Введите желаемую должность"
            type="search"/>
        </MainInputSearch>

        <MainSelectArea>
          <MainSelect>
            <MainInputSearchIcon>
              {IconSvg.contact.address("#BD0A2F")}
            </MainInputSearchIcon>
            {status === "idle"
              &&
              <SkeletonTheme baseColor="#f3f3f3" highlightColor="#ecebeb">
                <Skeleton className="search-skeleton" count={1}/>
              </SkeletonTheme>
            }
            {status === "success"
              &&
              <Select
                // menuIsOpen={true}
                styles={colourStyles}
                defaultValue={cities[city]}
                placeholder={<div>Выберите регион</div>}
                onChange={e => changeCity(e)}
                options={cities}
                components={{
                  Option: CustomOption,
                  MenuList: CustomMenuList,
                  Container: CustomContainer,
                  // ValueContainer: CustomValueContainer,
                  // DropdownIndicator,
                  IndicatorSeparator: () => null,
                  Control: CustomControl,
                  Input: CustomInput
                }}
              />
            }
          </MainSelect>
        </MainSelectArea>
        <MainSearchButton type="submit">
          <span>
             Найти вакансию
          </span>
          {IconSvg.ui.search()}

        </MainSearchButton>
      </MainSearchBody>

    </MainSearchEl>
  );
};

export default MainSearch;
