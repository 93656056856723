import styled from "styled-components";
import {baseThemeStyled} from "../../assets/styles/BaseTheme.styled";
import {Link} from "react-router-dom";
import {Button} from "../../assets/styles/Main.styled";
import {css} from "styled-components/macro";
import ArrowImage from "../../assets/images/slider-arrow-2.png"

export const CardVacancyListEl = styled.div.attrs({
  "data-id": "card-vacancy-list-el"
})`
  user-select: none;
  //margin-right: -14px;
  //margin-left: -14px;
  //display: flex;
  padding-bottom: 20px;
  //.swiper-horizontal{
  //  padding-bottom: 60px;
  //}
  .swiper-button-next, .swiper-button-prev{
    margin-top: auto;
    top: auto;
  }
  .swiper-pagination-fraction,
  .swiper-button-next, .swiper-button-prev{
    bottom: -0px;
    display: none;
  }
  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after,
  .swiper-button-prev:after, .swiper-rtl .swiper-button-prev:after{
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${ArrowImage});
    width: 6px;
    height: 19px;
  }
  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
    transform: rotate(180deg);
  }
  @media ${baseThemeStyled.breakpoints.small} {
    .swiper-pagination-fraction,
    .swiper-button-next, .swiper-button-prev{
      display: block;
    }
  }
  
`

export const CardVacancyListCol= styled.div.attrs({
  "data-id": "card-vacancy-list-col"
})`
  //padding: 0 14px;
`

export const RedLine= styled.div.attrs({
  "data-id": "red-line"
})`
  margin: 0 auto;
  width: 100px;
  height: 5px;
  background: #C21C3F;
  border-radius: 5px
`

export const CardVacancyEl = styled.div.attrs({
  "data-id": "card-vacancy-el"
})`
  //max-width: 345px;
  //width: 100%;
  border-radius: 3px;
  overflow: hidden;
  //background-color: #ABABAE;
  //padding-top: 9px;
  
`

export const CardVacancyBody = styled.div.attrs({
  "data-id": "card-vacancy-body"
})`
  background-color: #fff;
  padding: 30px 25px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  @media ${baseThemeStyled.breakpoints.medium} {
    padding: 15px 15px 20px 15px
  }
`


export const CardVacancyHeader = styled.div.attrs({
  "data-id": "card-vacancy-header"
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 7px;
`

export const CardVacancyLocation = styled.div.attrs({
  "data-id": "card-vacancy-location"
})`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  //flex-wrap: wrap;
  margin-bottom: 10px;
`

export const CardVacancyPoint = styled.div.attrs({
  "data-id": "card-vacancy-point"
})`
  margin-right: 11px;
  @media ${baseThemeStyled.breakpoints.medium} {
    margin-right: 6px;
  }
`

export const CardVacancyCity = styled.div.attrs({
  "data-id": "card-vacancy-city"
})`
  color: #373737;
  font: 600 16px/19px ${baseThemeStyled.fontsFamily.Lato}
  @media ${baseThemeStyled.breakpoints.medium} {
    font: 600 15px/18px ${baseThemeStyled.fontsFamily.Lato}
  }
`

export const CardVacancyMode = styled.div.attrs({
  "data-id": "card-vacancy-mode"
})`
  background-color: #E4E9EE;
  padding: 7px 10px;
  border-radius: 3px;
  color: #373737;
  font: 600 13px/16px ${baseThemeStyled.fontsFamily.Lato};
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  
`

export const CardVacancyModeIcon = styled.div.attrs({
  "data-id": "card-vacancy-mode-icon"
})`
  margin-right: 5px;
`

export const CardVacancyInfo = styled.div.attrs({
  "data-id": "card-vacancy-info"
})`
  margin-bottom: 20px;
  position: relative;
  @media ${baseThemeStyled.breakpoints.medium} {
    margin-bottom: 30px;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    margin-bottom: 20px;
  }
`

export const CardVacancyTitle = styled(Link).attrs({
  "data-id": "card-vacancy-main-title"
})`
  display: block;
  text-decoration-line: none;
  margin-bottom: 15px;
  color: #000;
  font: 500 23px/26px ${baseThemeStyled.fontsFamily.Lato};
  @media ${baseThemeStyled.breakpoints.medium} {
    font: 600 18px/21px ${baseThemeStyled.fontsFamily.Lato};
    margin-bottom: 8px;
  }
`

export const CardVacancyP = styled.p.attrs({
  "data-id": "card-vacancy-p"
})`
  color: #000;
  font: 400 15px/21px ${baseThemeStyled.fontsFamily.Lato};
  margin-bottom: 60px;
  @media ${baseThemeStyled.breakpoints.medium} {
    margin-bottom: 20px;
    font: 400 14px/18px ${baseThemeStyled.fontsFamily.Lato};
  }
`

export const CardVacancyPrice = styled.span.attrs({
  "data-id": "card-vacancy-price"
})`
  color: #38454E;
  font: 600 21px/32px ${baseThemeStyled.fontsFamily.Lato};
`

export const CardVacancyButtons = styled.div.attrs({
  "data-id": "card-vacancy-buttons"
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${baseThemeStyled.breakpoints.medium} {
    flex-direction: column;
  }
`

const CardVacancyButtonCss = css`
  color: #fff;
  border-radius: 3px;
  padding: 7px 16px;
  font: 700 13px/16px ${baseThemeStyled.fontsFamily.Lato};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration-line: none;
  transition: 0.3s linear background-color;
  @media ${baseThemeStyled.breakpoints.medium} {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const CardVacancyButtonMore = styled(Link).attrs({
  "data-id": "card-vacancy-button-more"
})`
  ${CardVacancyButtonCss};
  background: #679CCE;
  &:hover, &:active, &:focus{
    background: #5E8EBB;
    color: #fff;
  }

`

export const CardVacancyButtonSend = styled(Button).attrs({
  "data-id": "card-vacancy-button-send"
})`
  ${CardVacancyButtonCss};
  background: #64A366;
  &:hover, &:active, &:focus{
    background: #609B62;
  }
`
