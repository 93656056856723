import {Container} from 'assets/styles/Main.styled';
import React from 'react';
import {MainDignitiesEl} from "./MainDignities.styled";
import "./style.sass"
import {IMAGES} from "../../../../constants/Images";
import MainTitle from "../main-title/MainTitle";
import { Link } from 'react-router-dom';

const MainDignities = () => {
  return (
    <Container>
      <MainTitle
        mb={25}
        lr_mb={25}
        md_mb={20}
        sm_mb={20}
        esm_mb={15}
        title="Почему нас выбирают?"
        link_title="Смотреть все преимущества"
        link_route="/advantages"
      />
      <MainDignitiesEl>
        <div className="dignities-list">

          <div className="dignities-item">
            <div className="dignities-item__image">
              <img src={IMAGES.DignitiesImage} alt=""/>
            </div>

            <div className="dignities-item__info">
              <h5 className="dignities-item__title">
                Обучение и развитие
              </h5>
              <p className="dignities-item__text">
                В МТК Росберг мы не только помогаем новым сотрудникам адаптироваться к работе, но и систематически
                проводим тренинги и вебинары.
              </p>
            </div>
          </div>

          <div className="dignities-item">
            <div className="dignities-item__image">
              <img src={IMAGES.DignitiesImage1} alt=""/>
            </div>

            <div className="dignities-item__info">
              <h5 className="dignities-item__title">
                Современный офис
              </h5>
              <p className="dignities-item__text">
                В 2020 году состоялось торжественное открытие нового здания MTK Росберг. В отделке интерьера
                использовались современные дизайнерские решения.
              </p>
            </div>
          </div>

          <div className="dignities-item">
            <div className="dignities-item__image">
              <img src={IMAGES.DignitiesImage2} alt=""/>
            </div>

            <div className="dignities-item__info">
              <h5 className="dignities-item__title">
                Бонусы
              </h5>
              <p className="dignities-item__text">
                Действует Корпоративное предложение
                для сотрудников. Например:
              </p>
              <ul className="dignities-item__ul">
                <li className="dignities-item__li">
                  <p className="dignities-item__text">
                    своя столовая;
                  </p>
                </li>
                <li className="dignities-item__li">
                  <p className="dignities-item__text">
                    скидки на продукцию;
                  </p>
                </li>
                <li className="dignities-item__li">
                  <p className="dignities-item__text">
                    скидки в фитнес-клуб.
                  </p>
                </li>

              </ul>
            </div>
          </div>

        </div>
        <div className="dignities-main">
          <div className="dignities-main-item">

            <div className="dignities-main-item__image">
              <img src={IMAGES.DignitiesImage3} alt=""/>
            </div>

            <div className="dignities-item__info">
              <h5 className="dignities-item__title">
                Мы ценим ваше время
              </h5>
              <p className="dignities-item__text">
                В МТК Росберг разработан четкий и понятный принцип взаимодействия всех подразделений компании. Но,
                вместе с тем, мы всегда в поиске новых методов упрощения и автоматизации как внутренних процессов, так и
                внешних.
              </p>
              <div className="dignities-item-status-area">
                <div className="dignities-item-status">
                  <strong>Наталия</strong>, руководитель отдела кадров
                </div>
              </div>
            </div>
          </div>

          <div className="dream-job-widget-area">
            <Link className="dream-job-widget" to="https://dreamjob.ru/employers/123711" target="_blank">
              <img
                data-id="wg-123711-1-1" width="170" height="114" title="Рейтинг работодателя на Dream Job"
                src="https://dreamjob.ru/uploads/widgets/wg-123711-1-1.svg?~~(Date.now()/864e5))"
                alt="МТК Росберг Центр отзывы сотрудников о работодателе на Dream Job" style={{border:0}}/>

              {/*<iframe src="https://dreamjob.ru/uploads/widgets/wg-123711-1-1.svg?~~(Date.now()/864e5))"></iframe>*/}
            </Link>
          </div>
        </div>
      </MainDignitiesEl>
    </Container>
  );
};

export default MainDignities;
