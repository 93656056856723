import React from 'react'
import {Routes, Route} from "react-router-dom";
import Header from "./component/Header/Header"
import MainPage from "./pages/main-page/MainPage"
import OurHistoryPage from "./pages/our-history-page/OurHistoryPage"
import ErrorPage from "./pages/error-page/ErrorPage"
import Footer from "./component/Footer/Footer"
import ScrollToTop from "./hooks/ScrollToTop"
import Cookie from "./component/Cookie/Cookie"
import FormModal from "./component/Modals/FormModal"
import MainLayout from "./layouts/MainLayout";
import {Section} from "./assets/styles/Main.styled";
import TeamPage from "./pages/team-page/TeamPage";
import PolicyPage from "./pages/policy-page/PolicyPage";
import VacanciePage from "./pages/vacancie-page/VacanciePage";
import CatalogPage from "./pages/catalog-page/CatalogPage";
import ContactPage from "./pages/contact-page/ContactPage";
import AdvantagesPage from "./pages/advantages-page/AdvantagesPage";
import SuccessModal from "./component/Modals/SuccessModal";
import UiPage from "./pages/ui-page/UiPage";
import {path} from "./utils/shared";

const AppRouter = () => {

  return (
    <MainLayout>
      <ScrollToTop>
        <Section>
          <Header/>
          <Routes>
            <Route path={path.main} element={<MainPage/>}/>
            <Route path={path.history} element={<OurHistoryPage/>}/>
            <Route path={path.advantages} element={<AdvantagesPage/>}/>
            <Route path={path.privacy} element={<PolicyPage/>}/>
            <Route path={path.contact} element={<ContactPage/>}/>
            <Route path={path.team} element={<TeamPage/>}/>
            <Route path={path.vacancyId} element={<VacanciePage/>}/>
            <Route path={path.vacancies} element={<CatalogPage/>}/>
            <Route path={path.error} element={<ErrorPage/>}/>
            <Route path="/dev/lib/ui" element={<UiPage/>}/>
          </Routes>
          <Footer/>
          <Cookie/>
        </Section>
        <FormModal/>
        <SuccessModal/>
      </ScrollToTop>
    </MainLayout>
  )
}

export default AppRouter
