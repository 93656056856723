import styled from "styled-components";
import {baseThemeStyled} from "../../assets/styles/BaseTheme.styled";
import {Link} from "react-router-dom";
import {CardVacancyButtons} from "../card-vacancy/CardVacancy.styled";

export const CatalogListEl = styled.div.attrs({
  "data-id": "catalog-list-el"
})`
  width: 100%;
`

export const CardVacancyBigListEl = styled.div.attrs({
  "data-id": "card-vacancy-big-list"
})`
  //display: grid;
  flex: 1 1 auto;
  width: 100%;
  //gap: 10px;

`

export const VacancyCardItem = styled.div.attrs({
  "data-id": "vacancy-card-item"
})`
  margin-bottom: 10px;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
`

export const VacancyGrid = styled.div.attrs({
  "data-id": "vacancy-grid"
})`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 15px;
  padding: 20px 30px 15px 30px;
  @media ${baseThemeStyled.breakpoints.large} {
    grid-template-columns: repeat(2, auto);
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    padding: 16px 12px 23px 12px;
  }
`

export const VacancyTitleGrid = styled.div.attrs({
  "data-id": "vacancy-title-grid"
})`
  grid-column: 1/3;
  grid-row: 1/2;
  @media ${baseThemeStyled.breakpoints.large} {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    grid-row: 2/3;
    grid-column: 1/3;
  }
`

export const VacancyTitle = styled(Link).attrs({
  "data-id": "vacancy-title"
})`
  font: 500 23px/30px ${baseThemeStyled.fontsFamily.Lato};
  color: #000;
  transition: color 0.3s ease-in-out;
  &:hover, &:active, &:focus{
    color: #BD0A2F;
  }
`

export const VacancyModeGrid = styled.div.attrs({
  "data-id": "vacancy-mode-grid"
})`
  grid-column: 3/4;
  grid-row: 1/2;
  justify-self: end;
  @media ${baseThemeStyled.breakpoints.large} {
    grid-column: 2/3;
    grid-row: 1/2;
  }
`

export const VacancyMode = styled.div.attrs({
  "data-id": "vacancy-mode"
})`
  display: flex;
  align-items: center;
  background: #E4E9EE;
  border-radius: 3px;
  padding: 7px 9px 7px 9px;
  position: relative;
  font: 700 13px/16px ${baseThemeStyled.fontsFamily.Lato};
  color: #373737;
`

export const VacancyCityGrid = styled.div.attrs({
  "data-id": "vacancy-city-grid"
})`
  display: flex;
  grid-row: 2/3;
  grid-column: 1/2;
  @media ${baseThemeStyled.breakpoints.large} {
    grid-row: 2/3;
    grid-column: 1/3;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    grid-column: 1/2;
    grid-row: 1/2;
    align-items: center;
  }
`

export const VacancyCity = styled.div.attrs({
  "data-id": "vacancy-city"
})`
  font: 600 16px/19px ${baseThemeStyled.fontsFamily.Lato};
  color: #373737;
  //padding-left: 18px;
  position: relative;
`

export const VacancyDescGrid = styled.div.attrs({
  "data-id": "vacancy-desc-grid"
})`
  grid-row: 3/4;
  grid-column: 1/4;
  @media ${baseThemeStyled.breakpoints.large} {
    grid-column: 1/3;
  }
`

export const VacancyDesc = styled.p.attrs({
  "data-id": "vacancy-desc"
})`
  font: 400 14px/20px ${baseThemeStyled.fontsFamily.Lato};
  color: #333333;
  padding-bottom: 5px;
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    padding-bottom: 0;
  }
`

export const VacancyButtonGrid = styled.div.attrs({
  "data-id": "vacancy-button-grid"
})`
  grid-row: 4/5;
  grid-column: 1/2;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
  align-self: center;
  @media ${baseThemeStyled.breakpoints.large} {
    gap: 10px;
    grid-row: 5/6;
    grid-column: 1/3;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    grid-template-columns: repeat(1, 1fr);
  }
  button, a{
    margin-bottom: 0;
  }
`

export const VacancyPaymentGrid = styled.div.attrs({
  "data-id": "vacancy-payment-grid"
})`
  grid-column: 2/4;
  grid-row: 4/5;
  justify-self: end;
  @media ${baseThemeStyled.breakpoints.large} {
    grid-column: 1/3;
    grid-row: 4/5;
    justify-self: start;
  }
`
export const VacancyPayment= styled.p.attrs({
  "data-id": "vacancy-payment"
})`
  font: 600 24px/29px ${baseThemeStyled.fontsFamily.Lato};
  color: #38454E;
  @media ${baseThemeStyled.breakpoints.large} {
    font-size: 21px;
  }
`

export const EmptyVacancies = styled.div.attrs({
  "data-id": "empty-vacancies"
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  b{
    font: 400 15px/21px ${baseThemeStyled.fontsFamily.Lato};
    margin-bottom: 30px;
    @media ${baseThemeStyled.breakpoints.small} {
      font: 400 14px/18px ${baseThemeStyled.fontsFamily.Lato};
    }
  }
  img{
    @media ${baseThemeStyled.breakpoints.small} {
      width: 105px;
      heigth: 105px
    }
  }
`
