import React from 'react';
import HelmetComponent from "../../component/Helmet/Helmet";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import {Container, Page, Section, Title, TitleArea} from "../../assets/styles/Main.styled";
import "./style.css"
import ic1 from "../../assets/images/ic1.svg"
import ic2 from "../../assets/images/ic2.svg"
import ic3 from "../../assets/images/ic3.svg"
import ic4 from "../../assets/images/ic4.svg"
import ic5 from "../../assets/images/ic5.svg"
import p1 from "../../assets/images/p1-1.jpg"
import p2 from "../../assets/images/p1-2.jpg"
import p3 from "../../assets/images/p1-3.jpg"
import p4 from "../../assets/images/p1-4.jpg"
import p21 from "../../assets/images/p2-1.jpg"
import p22 from "../../assets/images/p2-2.jpg"
import p23 from "../../assets/images/p2-3.jpg"
import p24 from "../../assets/images/p2-4.jpg"
import Flag1 from "../../assets/images/image1.png"
import Flag2 from "../../assets/images/image2.png"
import Flag3 from "../../assets/images/image3.png"
import Flag4 from "../../assets/images/image4.png"
import Flag5 from "../../assets/images/image5.png"
import {
  HistoryBrandBlock, HistoryFlag, HistoryFlags,
  HistoryStat,
  HistoryStatArea,
  HistoryStatCol,
  HistoryText,
  HistoryTextGreen, HistoryWork, HistoryWorkTitle
} from "./OurHistoryPage.styled";
import MainBrandSlider from "../main-page/components/main-brand-slider/MainBrandSlider";

const OurHistoryPage = () => {

  return (
    <Page
      style={{paddingBottom: 0}}
      bgColor="#F4F4F4">
      <HelmetComponent
        title="Наша история"
        content="Наша история"
      />
      <Breadcrumbs/>
      <Container>
        <TitleArea>
          <Title>
            Наша история
          </Title>
        </TitleArea>
      </Container>

      <Container>
        <div className="about__first-block">
          <div className="about__text-wrapper">
            <p className="about__text">
                  <span>
                    ООО «МТК Росберг Центр»
                  </span>
              — это крупный и надежный поставщик шин, оригинальных и аналоговых запасных частей, а также
              сопутствующих
              услуг по легковому, грузовому шиномонтажу, ремонту легковых и грузовых автомобилей. Компания успешно
              функционирует более 14 лет.
            </p>
            <p className="about__text">
              МТК Росберг является официальным дилером более 20 крупнейших производителей шин, а также
              самостоятельно импортирует более 15 всемирно известных брендов по шинам. </p>
            <p className="about__text">
              Последние 7 лет МТК Росберг, активно развивающаяся компания, по продаже товаров и услуг своего профиля. За
              это время были открыты филиалы и подразделения в Москве, Ростове-на-Дону, Новосибирске, Санкт-Петербурге,
              Набережных Челнах. Штат сотрудников составляет более 400 человек.
            </p>
            <p className="about__text">
              И на этом не собирается останавливаться! </p>
            <p className="about__text">
              Это отличная возможность сделать карьеру и быть частью создания новых направлений и проектов!</p>
          </div>

          <p className="about__title-red">
            Важное о нас
          </p>

          <ul className="about__list">
            <li className="about__item">
              <p className="about__text about__text--list">
                Мы предоставляем работу в стабильно развивающейся компании; </p>
            </li>

            <li className="about__item">
              <p className="about__text about__text--list">Мы даём достойную оплату труда;</p>
            </li>

            <li className="about__item">
              <p className="about__text about__text--list">В нашей компании высшее руководство открыто к диалогу с
                сотрудниками;</p>
            </li>

            <li className="about__item">
              <p className="about__text about__text--list">Мы готовы применить ваши навыки и умения для общей цели
                компании.</p>
            </li>
            <li className="about__item">
              <p className="about__text about__text--list">Мы не обходимся без тимбилдинга (в молодом и энергичном
                коллективе).
              </p>
            </li>
          </ul>
          <p className="about__text about__text--pl">
            Наш принцип – создание сильной команды, работающей на результат и достигающей новых высот!
          </p>
        </div>
      </Container>

      <Section>
        <Container>
          <div className="about-grid grid">
            <div className="about-grid__block">
              <div className="y1">
                <p className="about-grid__year">
                  2009
                </p>
              </div>
              <div className="y1d1">
                <p className="about-grid__desc-text">2 человека</p>
                <p className="about-grid__desc-title">численность
                  сотрудников</p>
              </div>
              <div className="y1d2">
                <p className="about-grid__desc-text">40 кв.м</p>
                <p className="about-grid__desc-title">площадь
                  склада</p>
              </div>
              <div className="y1d3">
                <p className="about-grid__desc-text">ЦФО</p>
                <p className="about-grid__desc-title">география
                  работы</p>
              </div>
              <div className="ic1">
                <img src={ic1}/>
              </div>
              <div className="p1-1">
                <img src={p1}/>
              </div>
              <div className="p1-2">
                <img src={p2}/>
              </div>
              <div className="p1-3">
                <img src={p3}/>
              </div>
              <div className="p1-4">
                <img src={p4}/>
              </div>
              <div className="ic2">
                <img src={ic2}/>
              </div>
              <div className="y2">
                <p className="about-grid__year">
                  2011
                </p>
              </div>
              <div className="y2d1">
                <p className="about-grid__desc-text">20 человек</p>
                <p className="about-grid__desc-title">численность
                  сотрудников</p>
              </div>
              <div className="y2d2">
                <p className="about-grid__desc-text">200 кв.м</p>
                <p className="about-grid__desc-title">площадь
                  склада</p>
              </div>
              <div className="y2d3">
                <p className="about-grid__desc-text">ЦФО</p>
                <p className="about-grid__desc-title">география
                  работы</p>
              </div>
              <div className="y3">
                <p className="about-grid__year">
                  2014
                </p>
              </div>
              <div className="y3d1">
                <p className="about-grid__desc-text">75 человек</p>
                <p className="about-grid__desc-title">численность
                  сотрудников</p>
              </div>
              <div className="y3d2">
                <p className="about-grid__desc-text">4000 кв.м</p>
                <p className="about-grid__desc-title">площадь
                  склада</p>
              </div>
              <div className="y3d3">
                <p className="about-grid__desc-text">ЦФО, ЮФО, ПФО</p>
                <p className="about-grid__desc-title">география
                  работы</p>
              </div>
              <div className="ic3">
                <img src={ic3}/>
              </div>
              <div className="ic4">
                <img src={ic4}/>
              </div>
              <div className="y4">
                <p className="about-grid__year">
                  2019
                </p>
              </div>
              <div className="y4d1">
                <p className="about-grid__desc-text">160 человека</p>
                <p className="about-grid__desc-title">численность
                  сотрудников</p>
              </div>
              <div className="y4d2">
                <p className="about-grid__desc-text">18 000 кв.м</p>
                <p className="about-grid__desc-title">площадь
                  склада</p>
              </div>
              <div className="y4d3">
                <p className="about-grid__desc-text">Россия</p>
                <p className="about-grid__desc-title">география
                  работы</p>
              </div>
              <div className="p2-1">
                <img src={p21}/>
              </div>
              <div className="p2-2">
                <img src={p22}/>
              </div>
              <div className="p2-3">
                <img src={p23}/>
              </div>
              <div className="p2-4">
                <img src={p24}/>
              </div>
              <div className="y5">
                <p className="about-grid__year">
                  2023
                </p>
              </div>
              <div className="y5d1">
                <p className="about-grid__desc-text"> {">"} 450 человек</p>
                <p className="about-grid__desc-title">численность
                  сотрудников</p>
              </div>
              <div className="y5d2">
                <p className="about-grid__desc-text">50 000 кв.м</p>
                <p className="about-grid__desc-title">площадь
                  склада</p>
              </div>
              <div className="y5d3">
                <p className="about-grid__desc-text">РФ и СНГ</p>
                <p className="about-grid__desc-title">география
                  работы</p>
              </div>
              <div className="ic5">
                <img src={ic5}/>
              </div>
            </div>
          </div>

          <HistoryTextGreen>
            На сегодняшний день общая площадь складов нашей компании составляет 50 000 кв. м, штат сотрудников —более
            450 человек, география работы охватывает все регионы России, а также страны таможенного союза ЕАЭС.
          </HistoryTextGreen>

          <HistoryStatArea>
            <HistoryStat>
              <HistoryStatCol>
                <span className="red">
                  20
                </span>
                <span className="text">
                  ПОДРАЗДЕЛЕНИЙ
                </span>
              </HistoryStatCol>

              <HistoryStatCol>
                <span className="black">
                  50 000 м²
                </span>
                <span className="text">
               ОБЩАЯ ПЛОЩАДЬ СКЛАДОВ
                </span>
              </HistoryStatCol>

              <HistoryStatCol>
                <span className="black">
                  {">"} 450
                </span>
                <span className="text">
                  СОТРУДНИКОВ
                </span>
              </HistoryStatCol>

            </HistoryStat>
          </HistoryStatArea>


          <HistoryText>

            На сегодняшний день общая площадь складов нашей компании составляет 50 000 м². м, штат сотрудников - более
            450 человек, география работы охватывает все регионы России, а также страны таможенного союза ЕАЭС.
            <br/><br/>
            В наличии всегда есть большой ассортимент шин и камер для сельскохозяйственной, строительной и грузовой
            техники. Постоянно ведется работа по увеличению ассортимента запасных частей, есть возможность осуществления
            поставок из стран Евросоюза по низким ценам в кратчайшие сроки.

          </HistoryText>

        </Container>

      </Section>

      <HistoryBrandBlock>
        <MainBrandSlider/>
      </HistoryBrandBlock>

      <Section>
        <Container>
          <HistoryWork>
            <HistoryWorkTitle>
              Мы работаем во всех странах Таможенного союза!
            </HistoryWorkTitle>
            <HistoryFlags>
              <HistoryFlag>
                <img src={Flag1} alt=""/>
                <span>
                  Россия
                </span>
              </HistoryFlag>
              <HistoryFlag>
                <img src={Flag2} alt=""/>
                <span>
                  Казахстан
                </span>
              </HistoryFlag>
              <HistoryFlag>
                <img src={Flag3} alt=""/>
                <span>
                  Белоруссия
                </span>
              </HistoryFlag>
              <HistoryFlag>
                <img src={Flag4} alt=""/>
                <span>
                 АРМЕНИЯ
                </span>
              </HistoryFlag>
              <HistoryFlag>
                <img src={Flag5} alt=""/>
                <span>
               КИРГИЗИЯ
                </span>
              </HistoryFlag>
            </HistoryFlags>
          </HistoryWork>
        </Container>
      </Section>

    </Page>
  );
};

export default OurHistoryPage;
