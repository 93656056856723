import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {InterviewContext} from "../../context/interview";
import {links} from "./header_helper";
import {
  HeaderDownArea,
  HeaderEl,
  HeaderNavigation,
  HeaderNavigationLi,
  HeaderNavigationLink,
  HeaderNavigationUl,
  HeaderUpArea,
  HeaderUpBody,
  HeaderNavigationLinkIcon,
  ButtonInterview,
  HeaderDown,
  LogoLink,
  HeaderContact,
  HeaderContactItem,
  HeaderContactLink,
  Hamburger,
  HamburgerButton
} from "./Header.styled"

import {IMAGES} from "../../constants/Images";
import {Container} from 'assets/styles/Main.styled';
import "./style.css"
import HeaderMobileMenu from "./components/MobileMenu";
import {IconSvg} from "../../utils/shared";
import {useActions} from "../../hooks/useActions";
import {useTypedSelector} from "../../hooks/useTypedSelection";

const Header = () => {

  const location =useLocation()

  const {setFormModal} = useContext(InterviewContext)

  const {isOpenMobileMenu} = useTypedSelector(state=>state.ui)

  const {setIsOpenMobileMenu} = useActions()

  useEffect(()=>{
    setIsOpenMobileMenu(false)
  },[location.pathname])

  return (
    <HeaderEl>
      <HeaderUpArea>
        <Container>
          <HeaderUpBody>
            <HeaderNavigation>
              <HeaderNavigationUl>
                {links.map((link, index) => {
                  if(link.visible){
                    return (
                      <HeaderNavigationLi key={index}>
                        <HeaderNavigationLink to={link.route}>
                          <HeaderNavigationLinkIcon>
                            {link.icon("#fff")}
                          </HeaderNavigationLinkIcon>
                          <span>{link.title}</span>
                        </HeaderNavigationLink>
                      </HeaderNavigationLi>
                    )
                  }
                })}
              </HeaderNavigationUl>
            </HeaderNavigation>
            <ButtonInterview onClick={()=>setFormModal(true)}>
              Записаться на собеседование
            </ButtonInterview>
          </HeaderUpBody>
        </Container>
      </HeaderUpArea>
      <HeaderDownArea>
        <Container>
          <HeaderDown>
            <Hamburger>
              <HamburgerButton
                onClick={() => setIsOpenMobileMenu(!isOpenMobileMenu)}>
                {!isOpenMobileMenu
                  ?
                  <img src={IMAGES.Icon8} alt="Меню"/>
                  :
                  <img src={IMAGES.Icon10} alt="Меню"/>
                }
              </HamburgerButton>
            </Hamburger>
            <LogoLink to="/">
              {IconSvg.logo.footer("#2F5379", 272, 27)}
            </LogoLink>
            <HeaderContact>
              <HeaderContactItem className="header-contact-address">
                {IconSvg.contact.address("#BD0A2F")}
                <span><strong>Орел, </strong>ул.Московское шоссе,173</span>
              </HeaderContactItem>
              <HeaderContactLink href={"tel: 84952564405"}>
                {IconSvg.contact.phone("#BD0A2F")}
                <span className="text"><span className="red">+7 (495)</span> 256-44-05</span>
              </HeaderContactLink>
              <HeaderContactLink
                href={"mailto: ok@mtkrosberg.ru"}>
                {IconSvg.contact.mail("#BD0A2F")}
                <span
                  className="text2">ok@<strong>mtkrosberg.ru</strong></span>
              </HeaderContactLink>
            </HeaderContact>
          </HeaderDown>
          {isOpenMobileMenu
            &&
            <HeaderMobileMenu/>
          }
        </Container>
      </HeaderDownArea>
    </HeaderEl>
  );
};

export default Header;
