import React from "react";
import {IconSvg} from "../../utils/shared";
import {path} from "../../utils/shared";

export interface  ILink {
  visible: boolean
  title: string,
  route: string,
  icon: any
}

export  const links:ILink[] = [
  {
    visible: true,
    title: 'О компании',
    route: path.history,
    icon(color : string){
      return IconSvg.menu.icon(color)
    }
  },
  {
    visible: true,
    title: 'Вакансии',
    route: path.vacancies,
    icon(color : string){
      return IconSvg.menu.icon1(color)
    }
  },
  {
    visible: true,
    title: 'Преимущества',
    route: path.advantages,
    icon(color : string){
      return IconSvg.menu.icon2(color)
    }
  },
  {
    visible: true,
    title: 'Коллектив',
    route: path.team,
    icon(color : string){
      return IconSvg.menu.icon3(color)
    }
  },
  {
    visible: true,
    title: 'Контакты',
    route: path.contact,
    icon(color : string){
      return IconSvg.menu.icon4(color)
    }
  },
  {
    visible: false,
    title: 'Пользовательское соглашение',
    route: path.privacy,
    icon(color : string){
      return null
    }
  }
]
