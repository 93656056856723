import {Container, Page, Title, TitleArea} from 'assets/styles/Main.styled';
import React from 'react';
import TeamBanner from "../../assets/images/team-banner.jpg"
import Team from "../../assets/images/team.jpg"
import "./style.sass";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import SliderLife from "./slider-life/SliderLife";
import ReviewsSlider from "./reviews-slider/ReviewsSlider";
import TeamSlider from "./team-slider/TeamSlider";
import HelmetComponent from "../../component/Helmet/Helmet";

const TeamPage = () => {
  return (
    <div className="team-page">
      <HelmetComponent
          title="О коллективе"
          content="О коллективе"
      />
      <div className="team-banner">
        <img src={TeamBanner} className="team-banner__img" alt=""/>
        <Container>
          <div className="team-title-block">
            <h1>
              О коллективе
            </h1>
            <h3>
              На сегодняшний день у нас работает Около 250 сотрудников в 30 подразделениях по всей стране.
              Наш опыт работы более 12 лет.
            </h3>
          </div>
        </Container>
      </div>
      <Page bgColor="#F4F4F4">
        <Breadcrumbs/>
        <Container>
          <TitleArea line>
            <Title>
              Руководство компании
            </Title>
          </TitleArea>
          <div className="team-block">
            <div className="team-block__image">
              <img src={Team} alt="Валухов Роман Владимирович"/>
            </div>
            <div className="team-block__body">
              <p className="team-block__text">
                Трудовые ресурсы – это самое ценное из всего, чем располагает наша компания. Все мы растем,
                совершенствуемся и достигаем новых высот. И успех каждого из нас приводит к росту и процветанию всей
                компании. Каждый новый сотрудник вносит в бизнес свою лепту, каждый приносит в компанию частичку себя и
                обогащает ее.
              </p>

              <div className="team-block__name-block">
                <div className="team-block__name">
                  <p className="name">Валухов Роман Владимирович</p>
                  <p className="status">Генеральный директор</p>
                </div>
              </div>

            </div>
          </div>

          <TeamSlider/>

        </Container>

        <ReviewsSlider/>

        <SliderLife/>

      </Page>
    </div>
  );
};

export default TeamPage;
