import { IVacancie } from "redux/Vacancies/VacanciesSlice";
import {IFilter} from "../redux/Filters/FiltersSlice";

export interface IResponceVacantions {
  data: {
    COUNT: number;
    ITEMS: IVacancie[];
    PAGE: number;
    SELECTED_COUNT: number;
  };
}

export interface ILocationState {
  pathname: string;
  search: string;
}

export enum EStatus {
  idle = "idle",
  success = "success",
  error = "error",
}

export interface ITitle {
  mb? : number,
  lr_mb?: number,
  md_mb?: number,
  sm_mb?: number,
  esm_mb?: number
}