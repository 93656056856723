import React, {FC, useContext} from 'react';
import {
  CardVacancyEl,
  CardVacancyBody,
  CardVacancyHeader,
  CardVacancyLocation,
  CardVacancyPoint,
  CardVacancyCity,
  CardVacancyMode,
  CardVacancyModeIcon,
  CardVacancyInfo,
  CardVacancyTitle,
  CardVacancyP,
  CardVacancyPrice, CardVacancyButtons, CardVacancyButtonMore, CardVacancyButtonSend
} from "./CardVacancy.styled";
import {IconSvg} from "../../utils/shared";
import {IVacancie} from "../../redux/Vacancies/VacanciesSlice";
import {InterviewContext} from "../../context/interview";
import {TypeJob} from "../../utils/Constants";

const CardVacancy: FC<IVacancie>  = ({  id,
                       name,
                       chart,
                       city,
                       preview_text,
                       pay_text,}) => {

  const {
    formModal, setFormModal,
  } = useContext(InterviewContext)

  return (
    <CardVacancyEl>
      <CardVacancyBody>
        <CardVacancyHeader>
          <CardVacancyLocation>
            <CardVacancyPoint>
              {IconSvg.contact.address("#BD0A2F")}
            </CardVacancyPoint>
            <CardVacancyCity>
              {city.value}
            </CardVacancyCity>
          </CardVacancyLocation>
          <CardVacancyMode>
            <CardVacancyModeIcon>
              {IconSvg.ui.mode()}
            </CardVacancyModeIcon>
            {TypeJob[chart.enum_id].text}
          </CardVacancyMode>
        </CardVacancyHeader>

        <CardVacancyInfo>
          <CardVacancyTitle  to={"/vacancy/" + id} target="_blank">
            {name}
          </CardVacancyTitle>
          <CardVacancyP  dangerouslySetInnerHTML={{__html: preview_text}}/>
          <CardVacancyPrice>
            {pay_text}
          </CardVacancyPrice>
        </CardVacancyInfo>

        <CardVacancyButtons>
          <CardVacancyButtonMore to={"/vacancy/" + id} target="_blank">
            Подробнее
          </CardVacancyButtonMore>
          <CardVacancyButtonSend   onClick={e => setFormModal(!formModal)}>
            Отправить резюме
          </CardVacancyButtonSend>
        </CardVacancyButtons>

      </CardVacancyBody>
    </CardVacancyEl>
  );
};

export default CardVacancy;