import React, {useContext} from 'react';
import {Modal} from "react-bootstrap";
import MainForm from "../../pages/main-page/components/main-form/MainForm";
import {InterviewContext} from "../../context/interview";
import FormSuccess from "../FormSuccess/FormSuccess";

const SuccessModal = () => {

  const {
    modalSuccess, setModalSuccess,
    resetFormInterview
  } = useContext(InterviewContext)

  const closeModal = () => {
    setModalSuccess(false)
    resetFormInterview()
  }

  return (
    <Modal
      centered
      contentClassName="modal-interview"
      show={modalSuccess}
      onHide={e => closeModal()}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <FormSuccess/>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;