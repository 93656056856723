import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
export const baseUrl = 'https://rest.mtkrosberg.ru'
export const ConstantsContacts = {
  phone: {

  },
  address:{

  },
  email:{

  }
}
export const TypeJob = {
  '247': {
    text: 'Полный день',
    class: 'all_day'
  },
  '248': {
    text: 'Сменный график',
    class: 'change_chart'
  },
  '249': {
    text: 'Гибкий график',
    class: 'flexible_chart'
  },
  '250': {
    text: 'Удаленная работа',
    class: 'flexible_chart'
  },
  '251': {
    text: 'Вахтовый метод',
    class: 'flexible_chart'
  },
}

export const CategoryType = {
  CHART: 'График работы',
  CITY: 'Город',
  DECORATION: 'Условия',
  SECTION: 'Специализация',
}

export const CategoryDic = {
  'FIND': 'FIND',
  'CITY': 'CITY[]',
  'SECTION': 'SECTION_ID[]',
  'CHART': 'CHART[]',
  'DECORATION': 'DECORATION[]'
}
export const Months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Инь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
];
export const Years = range(1950, getYear(new Date()) + 1, 1);
