import styled from "styled-components";
import {baseThemeStyled} from "../../../../assets/styles/BaseTheme.styled";
import {css} from "styled-components/macro";
import {Section} from "../../../../assets/styles/Main.styled";
import {Input} from "react-select/animated";
import {IMAGES} from "../../../../constants/Images"

export const SubTitle = styled.h4.attrs({
  "data-id": "sub-title"
})`
  color: #38454E;
  font: 400 18px/28px ${baseThemeStyled.fontsFamily.Lato};
  margin-bottom: 20px;
  @media ${baseThemeStyled.breakpoints.large}{
    margin-top: 10px;
    font: 400 16px/32px ${baseThemeStyled.fontsFamily.Lato};
    margin-bottom: 10px;
  }
  @media ${baseThemeStyled.breakpoints.large}{
    margin-bottom: 10px;
    font: 400 15px/21px ${baseThemeStyled.fontsFamily.Lato};
  }
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    margin-bottom: 0px;
  }
`

export const MainSectionForm = styled(Section).attrs({
  "data-id": "main-section-form"
})`
  //overflow: hidden;
  padding: 34px 0 77px 0;
  [data-id="lending-title"] {
    margin-bottom: 0px;
  }
  @media ${baseThemeStyled.breakpoints.large}{
    padding-top: 20px;
    padding-bottom: 34px
  }
  @media ${baseThemeStyled.breakpoints.medium}{
    padding-top: 25px;
    padding-bottom: 40px
  }
  @media ${baseThemeStyled.breakpoints.small}{
    padding-bottom: 30px
  }
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    padding-top: 15px;
    padding-bottom: 0px
  }
`

export const MainSectionFormImage = styled.div.attrs({
  "data-id": "main-section-form-image"
})`
  position: absolute;
  background-image: url(${IMAGES.FormSectionImage1});
  width: 326px;
  height: 140px;
  bottom: -80px;
  right: -110px;
  z-index: 1;
`

