import React from 'react';
import Team1 from "../../../assets/images/team1.jpg";
import Team2 from "../../../assets/images/team2.jpg";
import Team3 from "../../../assets/images/team3.jpg";
import {Pagination} from "swiper";
import {SwiperSlide, Swiper} from "swiper/react";

const TeamSlider = () => {
  return (
    <div className="team-card-area">
      <div className="team-card-slider">
        <Swiper
          autoHeight={true}
          loop={false}
          spaceBetween={30}
          slidesPerView={3}
          pagination={{
            clickable: true
          }}
          modules={[Pagination]}
          className="swiper-with-pagination"
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          }}
        >
          <SwiperSlide>
            <div className="team-card">
              <div className="team-block__image">
                <img src={Team1} alt="Тулинов
Иван Александрович "/>
              </div>
              <p className="team-card__name">
                Тулинов
                <br/>Иван Александрович
              </p>
              <p className="team-card__status">
                Директор по продажам
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="team-card">
              <div className="team-block__image">
                <img src={Team2} alt="Осюшкин
 Александр Сергеевич"/>
              </div>
              <p className="team-card__name">
                Осюшкин
                <br/>Александр Сергеевич
              </p>
              <p className="team-card__status">
                Коммерческий директор
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="team-card">
              <div className="team-block__image">
                <img src={Team3} alt="Журавлев
Александр Юрьевич
"/>
              </div>
              <p className="team-card__name">
                Журавлев
                <br/> Александр Юрьевич
              </p>
              <p className="team-card__status">
                Директор по развитию
              </p>
            </div>
          </SwiperSlide>

        </Swiper>
      </div>
    </div>
  );
};

export default TeamSlider;