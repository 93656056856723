import styled, {css} from "styled-components/macro";
import {baseThemeStyled} from "../../assets/styles/BaseTheme.styled";
import {Button, Page} from "../../assets/styles/Main.styled";
import {Link} from "react-router-dom";

export const VacanciePageEl = styled.div.attrs({
  "data-id": "vacancie-page-el"
})`
  position: relative;
  padding-bottom: 30px;
`

export const VacancieTitle = styled.h1.attrs({
  "data-id": "vacancie-title"
})`
  margin-bottom: 4px;
  font: 500 23px/34px ${baseThemeStyled.fontsFamily.Lato};
   @media ${baseThemeStyled.breakpoints.large}{
     font: 500 21px/34px ${baseThemeStyled.fontsFamily.Lato};
   }
`

export const VacanciePriceArea = styled.div.attrs({
  "data-id": "vacancie-price-area"
})`
  position: relative;
  margin-bottom: 24px;
  span{
    color: #38454E;
    font: 600 24px/28px ${baseThemeStyled.fontsFamily.Lato};
  }
  @media ${baseThemeStyled.breakpoints.large}{
    margin-bottom: 14px;
  }
`

export const VacancieAddress = styled.div.attrs({
  "data-id": "vacancie-address"
})`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  [data-id="card-vacancy-point"]{
    margin-right: 5px;
  }
`

export const VacancieInfo = styled.p.attrs({
  "data-id": "vacancie-info"
})`
  max-width: 785px;
  width: 100%;
  color: #000;
  font: 400 14px/20px ${baseThemeStyled.fontsFamily.Lato};
  margin-bottom: 24px;
`

export const VacancieMore = styled.ul.attrs({
  "data-id": "vacancie-more"
})`
  display: flex;
  flex-direction: column;
`

export const VacancieLi = styled.li.attrs({
  "data-id": "vacancie-li"
})`
  font: 400 15px/20px ${baseThemeStyled.fontsFamily.Lato};
  margin-bottom: 1px;
  strong{
    color: #000;
    font-weight: 900;
  }
`

export const VacancieOtherArea = styled.div.attrs({
  "data-id": "vacancie-other-area"
})`
  margin-top: 25px;
  border-top: 1px solid #B6BABD;
  padding-top: 24px;
`

export const VacancieOther = styled.div.attrs({
  "data-id": "vacancie-other"
})`
  max-width: 920px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
   @media ${baseThemeStyled.breakpoints.small}{
    flex-direction: column;
     margin-bottom: 10px;
   }
`

export const VacancieOtherCol = styled.div.attrs({
  "data-id": "vacancie-other-col"
})`
  width: 50%;
 @media ${baseThemeStyled.breakpoints.small}{
  margin-bottom: 15px;
  width: 100%;
 }
`

export const VacancieOtherTitle = styled.div.attrs({
  "data-id": "vacancie-other-title"
})`
  color: #1D293F;
  font: 600 21px/29px ${baseThemeStyled.fontsFamily.Lato};
  margin-bottom: 14px;
`

export const VacancieOtherUl = styled.ul.attrs({
  "data-id": "vacancie-other-ul"
})`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
`

const VacancieOtherLiCss = css`
  color: #333;
  font: 400 15px/21px ${baseThemeStyled.fontsFamily.Lato};
  position: relative;
  padding-left: 15px;
  margin-bottom: 5px;
  &:before{
    content: "";
    background-color: #BD0A2F;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    //top: calc(50% - 2.5px);
    top: 8px
  }
`
export const VacancieOtherLi = styled.li.attrs({
  "data-id": "vacancie-other-li"
})`
 ${VacancieOtherLiCss}
 
`

export const VacancieButtons = styled.div.attrs({
  "data-id": "vacancie-buttons"
})`
  display: flex;
  flex-wrap: wrap;
  button, a{
    margin-right: 10px;
    margin-bottom: 10px;
    &:last-child{
      margin-right: 0;
    }
  }
  
  button{
     @media ${baseThemeStyled.breakpoints.small}{
      width: 100%;
     }
  }
`

export const VacancieButtonWrite = styled(Button).attrs({
  "data-id": "vacancie-button-write"
})`
  background-color: #E4E9EE;
  padding: 11px 20px;
  color: #373737;
  font: 600 14px/17px ${baseThemeStyled.fontsFamily.Lato};
  svg{
    margin-right: 10px;
  }
  &:hover, &:active, &:focus{
    background-color: #BD0A2F;
    color: #fff;
    svg path{
      fill: #fff;
    }
  }
   @media ${baseThemeStyled.breakpoints.small}{
    white-space: nowrap;
   }
`

const VacancieButtonShowCss = css`
  padding: 13px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 3px;
  font: 600 14px/17px ${baseThemeStyled.fontsFamily.Lato};
  border: 1px solid #B6BABD;
  &:hover, &:active, &:focus{
    color: #BD0A2F;
    border-color: #2F5379;
  }
   @media ${baseThemeStyled.breakpoints.small}{
    padding: 10px;
    min-height: 40px;
   }
`

export const VacancieButtonShow = styled(Button).attrs({
  "data-id": "vacancie-button-show"
})`
 ${VacancieButtonShowCss}
`

export const VacancieButtonLink = styled(Link).attrs({
  "data-id": "vacancie-button-link"
})`
  ${VacancieButtonShowCss};
  padding: 7px 10px;
`

export const VacancieContactArea = styled.div.attrs({
  "data-id": "vacancie-contact-area"
})`
  margin-top: 25px;
  margin-bottom: 0;
  [data-id="vacancie-other"]{
    margin-bottom: 0;
  }
  [data-id="vacancie-other-li"],
  [data-id="vacancie-contact-li"]{
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    &:before{
      display: none;
    }
    @media ${baseThemeStyled.breakpoints.small}{
      flex-wrap: nowrap;
    }
  }
`

export const VacancieContactIcon = styled.div.attrs({
  "data-id": "vacancie-contact-icon"
})`
  position: relative;
  width: 14px;
  margin-right: 8px;
`

export const VacancieContactLink = styled.a.attrs({
  "data-id": "vacancie-contact-li"
})`
  ${VacancieOtherLiCss};
`

export const VacanciePageSliderArea = styled(Page).attrs({
  "data-id": "vacancie-page-slider-area"
})`
  padding-bottom: 0;
`
