import React, {useEffect, useState} from 'react';
// import Close from "../../assets/images/close.png"
import {IconSvg} from "../../utils/shared";

const Cookie = () => {
  const [open, setOpen] = useState(true)

  const closeModal =(value)=>{
    setOpen(value)
    let limit = 24 * 3600 * 1000; // 24 часа
    localStorage.setItem('modalCookie', value);
    // localStorage.setItem('localStorageInitTime', limit);
  }

  const init = async ()=>{
    let res = await localStorage.getItem('modalCookie');
    let limit = 24 * 3600 * 1000; // 24 часа
    let localStorageInitTime = localStorage.getItem('localStorageInitTime');
    // console.log(+new Date())
    // console.log(localStorageInitTime)
    // console.log(limit)
    // console.log(+new Date() - localStorageInitTime > limit)
    if(!localStorageInitTime){
      // console.log(1)
      localStorage.setItem('localStorageInitTime', +new Date());
    }if(+new Date() - (+localStorageInitTime) > limit){
      // console.log(2)
      localStorage.clear();
      localStorage.setItem('localStorageInitTime', +new Date());
    }
    if(!res){
      // console.log(3)
      await setOpen(true)
    }if(res){
      // console.log(4)
      await setOpen(JSON.parse(res))
    }
  }

  useEffect(()=>{
    init()
  },[])

  if(!open) return <div/>
  return (
    <div className="cookie-area">
      <div className="cookie">
        <button className="button cookie__button"
                onClick={()=>closeModal(false)}>
          {IconSvg.ui.close()}
          {/*<img src={Close} alt="Закрыть"/>*/}
        </button>
        <span className="cookie__text">
          Мы используем файлы cookie. Они помогают улучшить ваше взаимодействие с сайтом.
        </span>
      </div>
    </div>
  );
};

export default Cookie;
