import React, {useState} from 'react';
import {
    HeaderSearchArea,
    HeaderSearchButton,
    HeaderSearchEl,
    HeaderSearchInput,
    HeaderSearchInputArea
} from "../Header.styled";
import {IconSvg} from "../../../utils/shared";
import {useLocation, useNavigate} from "react-router-dom";
import {useActions} from "../../../hooks/useActions";

const HeaderSearch = () => {

    const navigate = useNavigate();

    const {setIsOpenMobileMenu} = useActions()

    const [searchField, setSearchField] = useState<string>("");

    const handleSearchField = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchField(e.target.value);
    };

    const Submit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpenMobileMenu(false)
        if (searchField) {
            navigate(`vacancies?FIND=${encodeURI(searchField)}`);
        } else {
            // return
            navigate(`vacancies?FIND=`);
        }
    };

    return (
        <HeaderSearchArea onSubmit={Submit}>
            <HeaderSearchEl>
                <HeaderSearchInputArea>
                    <HeaderSearchInput
                        onChange={handleSearchField}
                        value={searchField || ""}
                        placeholder="Введите желаемую должность"
                        type="search"/>
                </HeaderSearchInputArea>
                <HeaderSearchButton type="submit">
                    {IconSvg.ui.search()}
                </HeaderSearchButton>
            </HeaderSearchEl>
        </HeaderSearchArea>
    );
};

export default HeaderSearch;
