import React, {memo, useContext, useState} from 'react';
import "./style.sass"
import {Modal, Button} from 'react-bootstrap';
// import Form from '../../component/Form/Form'
import {InterviewContext} from "../../context/interview"
import FormSuccess from "../../component/FormSuccess/FormSuccess"
import MainForm from "../../pages/main-page/components/main-form/MainForm";

const FormModal = () => {

  const {
    formModal, setFormModal,
    showCommentInput, setShowCommentInput,
    success, setSuccess,
    loadingSuccess, setLoadingSuccess,
    resetFormInterview
  } = useContext(InterviewContext)

  const closeModal = () => {
    setShowCommentInput(false)
    setFormModal(false)
    resetFormInterview()
  }

  return (
    <>
      <Modal
        centered
        contentClassName="modal-interview"
        show={formModal}
        onHide={e => closeModal()}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>

          <Modal.Title>Записаться на собеседование</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          {/*{success*/}
          {/*  ?*/}
          {/*  <FormSuccess/>*/}
          {/*  :*/}
          {/*  <MainForm />*/}
          {/*}*/}
          <MainForm/>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(FormModal);
