import React, {FC, useContext} from 'react';
import {
  VacancyButtonGrid,
  VacancyCardItem, VacancyCity, VacancyCityGrid, VacancyDesc, VacancyDescGrid,
  VacancyGrid,
  VacancyMode,
  VacancyModeGrid, VacancyPayment, VacancyPaymentGrid,
  VacancyTitle,
  VacancyTitleGrid
} from "./CardVacancyBig.styled";
import {
  CardVacancyButtonMore, CardVacancyButtons,
  CardVacancyButtonSend,
  CardVacancyMode,
  CardVacancyModeIcon, CardVacancyPoint
} from "../card-vacancy/CardVacancy.styled";
import {IconSvg} from "../../utils/shared";
import {IVacancie} from "../../redux/Vacancies/VacanciesSlice";
import {InterviewContext} from "../../context/interview";
import {TypeJob} from "../../utils/Constants";

const CardVacancyBig: FC<IVacancie> = ({
                                         id,
                                         name,
                                         chart,
                                         city,
                                         preview_text,
                                         pay_text,
                                       }) => {

  const { setFormModal } = useContext(InterviewContext);

  return (
    <VacancyCardItem>
      <VacancyGrid>
        <VacancyTitleGrid>
          <VacancyTitle to={"/vacancy/" + id} target="_blank">
            {name}
          </VacancyTitle>
        </VacancyTitleGrid>

        <VacancyModeGrid>
          <VacancyMode>
            <CardVacancyModeIcon>
              {IconSvg.ui.mode()}
            </CardVacancyModeIcon>
            {TypeJob[chart.enum_id].text}
          </VacancyMode>
        </VacancyModeGrid>

        <VacancyCityGrid>
          <CardVacancyPoint>
            {IconSvg.contact.address("#BD0A2F")}
          </CardVacancyPoint>
          <VacancyCity>
            {city.value}
          </VacancyCity>
        </VacancyCityGrid>

        <VacancyDescGrid>
          <VacancyDesc
            dangerouslySetInnerHTML={{ __html: preview_text ? preview_text : "" }}/>

        </VacancyDescGrid>

        <VacancyButtonGrid>
          <CardVacancyButtonMore  to={"/vacancy/" + id} target="_blank">
            Подробнее
          </CardVacancyButtonMore>
          <CardVacancyButtonSend    onClick={(e) => setFormModal(true)}>
            Отправить резюме
          </CardVacancyButtonSend>
        </VacancyButtonGrid>

        <VacancyPaymentGrid>
          <VacancyPayment>
            {pay_text}
          </VacancyPayment>
        </VacancyPaymentGrid>

      </VacancyGrid>

    </VacancyCardItem>
  );
};

export default CardVacancyBig;
