import {
  Checkbox,
  CheckboxInput,
  CheckboxLabel, CheckboxLink,
  CheckboxSquare, CheckboxText,
  Container,
  Page,
  Title,
  TitleArea
} from 'assets/styles/Main.styled';
import React, {useContext, useMemo, useRef, useState} from 'react';
import {YMaps, Map, Placemark} from '@pbe/react-yandex-maps';
import InputMask from 'react-input-mask'
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import {
  ContactButton, ContactCheckbox,
  ContactForm,
  ContactFormArea, ContactFormInputGroup, ContactFormText,
  ContactGrid,
  ContactsAddressBlock,
  ContactsAddressItem, ContactsAddressItemIcon,
  ContactsAddressList, ContactsAddressText,
  ContactsAddressTitle,
  ContactsAddressWrapper,
  ContactsBlockTitle,
  ContactsDesc,
  ContactsLink,
  ContactsLinkMail,
  ContactsMailGrid, ContactsMapBlock,
  ContactsName,
  ContactsNameGrid,
  ContactsPersonalGrid,
  ContactsTelGrid,
  ContactsText
} from "./ContactPage.styled";
import {IconSvg} from "../../utils/shared";
import {places} from "./contact_data";
import {InterviewContext} from "../../context/interview";
import api from "../../api/api";
import HelmetComponent from "../../component/Helmet/Helmet";

const ContactPage = () => {

  const {
    phone, setPhone, agreement, setAgreement, success, setSuccess,
    loadingSuccess, setLoadingSuccess, setModalSuccess
  } = useContext(InterviewContext)

  const [phoneError, setPhoneError] = useState('')

  const [selectPoint, setSelectPoint] = useState(places[0].geometry);

  const [zoom, setZoom] = useState(9)

  const [errorPhone, setErrorPhone] = useState<string>("")

  const map = useRef<any>(null);

  const myPanTo = (coordinates: number[]) => {

    map.current?.panTo(coordinates,{
      flying: true,
      duration: 1000,
    }).then(()=>{
      map.current?.setZoom(12, {duration: 1000})
    });

  };

  const handleCheckAgreement = useMemo(() => (e:any, agr: boolean) => {
    e.preventDefault()
    e.stopPropagation()
    setAgreement(!agr)
  }, [agreement])

  const submitForm = (e:any) => {
    e.preventDefault()
    e.stopPropagation()
    if (!agreement) return
    setLoadingSuccess(true)
    setPhoneError('')
    const formData = new FormData()
    formData.append('PHONE', phone)
    formData.append('ACTION', 'CALLBACK')
    api.getInterview(formData).then((resp:any) => {
      setPhone('')
      setSuccess(true)
      setLoadingSuccess(false)
      setAgreement(false)
      setModalSuccess(true)

    }).catch(e => {
      // console.log(e.response.data.ERROR)
      setPhoneError(e.response.data.ERROR.phone)
      setLoadingSuccess(false)
    })
  }

  return (
    <Page bgColor={"#F4f4f4"}>
      <HelmetComponent
          title="Контакты"
          content="Контакты"
      />
      <Breadcrumbs/>
      <Container>
        <TitleArea line>
          <Title>
            Контакты
          </Title>
        </TitleArea>
        <ContactGrid>
          <ContactsNameGrid>
            <ContactsName>
              ООО “МТК Росберг Центр”
            </ContactsName>
          </ContactsNameGrid>

          <ContactsTelGrid>
            <ContactsBlockTitle>
              Телефон для связи
            </ContactsBlockTitle>

            <ContactsLink href="tel:+79208094077">
              +7 (920) 809-40-77
            </ContactsLink>
            <ContactsLink href="tel:+74862442525">
              8 (4862) 44-25-25 доб. 550
            </ContactsLink>
            <ContactsLink href="tel:84862210210">
              8 (4862) 210 210
            </ContactsLink>

            <ContactsDesc>
              звонить пн-пт с 9 до 18
            </ContactsDesc>
          </ContactsTelGrid>

          <ContactsMailGrid>
            <ContactsBlockTitle>
              Email
            </ContactsBlockTitle>
            <ContactsLinkMail href="mailto:ok@mtkrosberg.ru">
              ok@mtkrosberg.ru</ContactsLinkMail>
            <ContactsDesc>
              для общей информации
            </ContactsDesc>
          </ContactsMailGrid>

          <ContactsPersonalGrid>
            <ContactsBlockTitle>
              Контактное лицо
            </ContactsBlockTitle>

            <ContactsText>
              Костюк Наталия Владимировна
            </ContactsText>

            <ContactsDesc>
              руководитель отдела кадров
            </ContactsDesc>

          </ContactsPersonalGrid>

        </ContactGrid>

        <ContactsAddressBlock>
          <ContactsAddressWrapper>
            <ContactsAddressTitle>
              Адреса филиалов
            </ContactsAddressTitle>
            <ContactsAddressList>

              {places.map((place, index)=>{
                return(
                  <ContactsAddressItem key={index}>
                    <ContactsAddressItemIcon>
                      {IconSvg.contact.address("#BD0A2F")}
                    </ContactsAddressItemIcon>

                  <ContactsAddressText style={{padding: 0}}>
                    <ContactsAddressText
                      dangerouslySetInnerHTML={{ __html: place.properties.balloonContent }}
                    />
                      <p onClick={() => myPanTo(place.geometry)}
                        className="contacts__adress-link">показать на карте
                      </p>
                    </ContactsAddressText>
                  </ContactsAddressItem>
                )
              })}

            </ContactsAddressList>
          </ContactsAddressWrapper>

          <ContactsMapBlock>
            <YMaps>
                <Map
                  options={{suppressMapOpenBlock: true}}
                  instanceRef={map}
                  modules={[]}
                  style={{width: "100%", height: "100%"}}
                  defaultState={{
                    center: selectPoint,
                    zoom: zoom,
                    controls: [],
                }} >
                  {places.map((place, index)=>{
                    return(
                      <Placemark
                        key={index}
                        {...place}
                      />
                    )
                  })}

                </Map>
            </YMaps>
          </ContactsMapBlock>
        </ContactsAddressBlock>

        <ContactFormArea onSubmit={submitForm}>

          <ContactFormText>
            Заполните форму и наши сотрудники обязательно свяжутся с вами в удобное для вас время!
          </ContactFormText>

          <ContactForm>

            <ContactFormInputGroup>
              <InputMask
                id="phone"
                // required={true}
                placeholder={"+7("}
                className={"form__input "}
                value={phone || ''}
                onChange={(e:any) => setPhone(e.target.value)}
                mask="+7(999) 999-99-99"
                maskChar={null}
              />
              <div
                  className={"form__error " + (phoneError ? 'form__error-visible ' : '')}>{phoneError}</div>
            </ContactFormInputGroup>

            <ContactButton
                type="submit"
                disabled={!agreement}>
              Отправить
            </ContactButton>

            <ContactCheckbox>
              <Checkbox>
                <CheckboxLabel
                    onClick={(e) => handleCheckAgreement(e, agreement)}>
                  <CheckboxInput checked={agreement} readOnly>

                  </CheckboxInput>
                  <CheckboxSquare>
                  </CheckboxSquare>

                  <CheckboxText>
                    Я согласен с <CheckboxLink to="/privacy">условиями передачи данных</CheckboxLink>
                  </CheckboxText>

                </CheckboxLabel>
              </Checkbox>
            </ContactCheckbox>

          </ContactForm>

        </ContactFormArea>

      </Container>
    </Page>
  );
};

export default ContactPage;
