import myIcon from "../../assets/images/icon-map.png"

const iconSize = [35, 35]

const iconOffset = [-10, -42]

const placeMark1 = {
  geometry: [53.014766, 36.156452],
  properties: {
    hintContent: '',
    balloonContent: `<span>Орел,</span> ул. Московское шоссе д. 173`
  },
  options: {
    iconLayout: "default#image",
    iconImageHref: myIcon,
    iconImageSize: iconSize,
    iconImageOffset: iconOffset,
  },
  modules: ['geoObject.addon.balloon', 'geoObject.addon.hint']
}

const placeMark2 = {
  geometry: [47.119537, 39.769738],
  properties: {
    hintContent: '',
    balloonContent: '<span>г. Батайск,</span> ул. Комарова, 181А (ОП Ростов -на-Дону)'
  },
  options: {
    iconLayout: 'default#image',
    iconImageHref: myIcon,
    iconImageSize: iconSize,
    iconImageOffset: iconOffset,
  },
  modules: ['geoObject.addon.balloon', 'geoObject.addon.hint']
}

const placeMark3 = {
  geometry: [54.955189, 82.852661],
  properties: {
    hintContent: '',
    balloonContent: '<span>г. Новосибирск,</span> ул. Петухова, 27/3, м. пл.Карла Маркса (ОП Новосибирск)'
  },
  options: {
    iconLayout: 'default#image',
    iconImageHref: myIcon,
    iconImageSize: iconSize,
    iconImageOffset: iconOffset,
  },
  modules: ['geoObject.addon.balloon', 'geoObject.addon.hint']
}

const placeMark4 = {
  geometry: [55.367019, 37.530418],
  properties: {
    hintContent: '',
    balloonContent: '<span>г. Подольск,</span> микрорайон Климовск, Московская ул., 19 (ОП Москва)'
  },
  options: {
    iconLayout: 'default#image',
    iconImageHref: myIcon,
    iconImageSize: iconSize,
    iconImageOffset: iconOffset,
  },
  modules: ['geoObject.addon.balloon', 'geoObject.addon.hint']
}
const placeMark5 = {
  geometry: [55.695119, 52.415987],
  properties: {
    hintContent: '',
    balloonContent: '<span>Республика Татарстан,</span> г.Набережные Челны, Мензелинский тракт д.52, офис 505'
  },
  options: {
    iconLayout: 'default#image',
    iconImageHref: myIcon,
    iconImageSize: iconSize,
    iconImageOffset: iconOffset,
  },
  modules: ['geoObject.addon.balloon', 'geoObject.addon.hint']
}
const placeMark6 = {
  geometry: [59.808500, 30.459311],
  properties: {
    hintContent: '',
    balloonContent: '<span>Санкт-Петербург,</span> Софийская улица, 121к4, посёлок Петро-Славянка, Колпинский район'
  },
  options: {
    iconLayout: 'default#image',
    iconImageHref: myIcon,
    iconImageSize: iconSize,
    iconImageOffset: iconOffset,
  },
  modules: ['geoObject.addon.balloon', 'geoObject.addon.hint']
}
export const places = [placeMark1, placeMark2, placeMark3, placeMark4, placeMark5, placeMark6]
