import React, {FC, useRef} from 'react';
import {Container} from "../../../../assets/styles/Main.styled";
import {IconSvg} from "../../../../utils/shared";
import "./search.sass"
import {HeaderSearchButton} from "../../../../component/Header/Header.styled";

interface ISearchForm {
  onSubmit?: any;
  searchField: string;
  handleSearchField: any;
  handleResetValue: any;
  // dropWindowVacancies: IVacancie[];
}

const Search: FC<ISearchForm> = ({
                                   onSubmit,
                                   searchField,
                                   handleSearchField,
                                   handleResetValue
                                   // dropWindowVacancies,
                                 }) => {

  const refDropDown = useRef<HTMLHeadingElement>(null);

  return (
    <Container>
      <form className="catalog-search" onSubmit={onSubmit}>
        <div className="catalog-search__body">
          <div className="catalog-search__icon">
            {IconSvg.ui.search("#000")}
          </div>
          <input
            onChange={handleSearchField}
            placeholder="Введите желаемую должность"
            value={searchField || ""}
            type="text"
            className="catalog-search__input"/>

          {searchField &&
            <button
              type="button"
              onClick={() => handleResetValue()}
              className="catalog-search__reset-button">
              {IconSvg.ui.reset()}
            </button>
          }

        </div>
        <button
          className="catalog-search__button"
          type="submit">
          <span>
            Искать
          </span>
            {IconSvg.ui.search()}
        </button>
      </form>
    </Container>
  );
};

export default Search;
