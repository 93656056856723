import React, {FC} from "react";
import axios from "axios";
// import FormModal from "../component/Modals/FormModal";
import ScrollToTop from "../hooks/ScrollToTop";
import {MainArea, GlobalStyle} from "../assets/styles/Main.styled";
import {baseUrl} from "../utils/Constants";

interface IMainLayout {
  children: React.ReactNode
}

const MainLayout: FC<IMainLayout> = ({children}) => {

  axios.defaults.baseURL = baseUrl

  return (
    <MainArea>
      <GlobalStyle/>
      {/*<FormModal/>*/}
      <ScrollToTop/>
      {children}
    </MainArea>
  )
}

export default MainLayout