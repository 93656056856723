import React, {useContext} from 'react'
import {InterviewContext} from "../../context/interview"

const FormSuccess = () => {

  const {
    setSuccess,formModal, setFormModal
  } = useContext(InterviewContext)

  const handleModal = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setSuccess(false)
    setFormModal(false)
  }


  return (

      <div className="form-interview-success ">
        <div className="form-interview-success__title">
          Ваша анкета отправлена!
        </div>
        <div className="form-interview-success__info">
          В ближайшее время с вами свяжется наш специалист.
        </div>
        {/*<div className="form-interview-success__button-area ">*/}
        {/*  <button*/}
        {/*    onClick={e => handleModal(e)}*/}
        {/*    className="button button__red form-interview-success__button__red">*/}
        {/*    Понятно*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>

  )
}

export default FormSuccess
