import React from 'react';
import "./ui-style.sass"
import {IconSvg} from "../../utils/shared";

const UiPage = () => {
  return (
    <div className="ui-page">
      <div className="ui-icon-area">
        {Object.keys(IconSvg).map(key =>{
          return(
            Object.keys(IconSvg[key]).map(icon =>{
              return(
                <div className="ui-icon">
                  {IconSvg[key][icon]("#fff")}
                </div>
              )
            })
          )
        })}
      </div>
    </div>
  );
};

export default UiPage;
