import React from 'react';
import {Link} from "react-router-dom"
import ErrorImg from "../../assets/images/error_404.png"
import HelmetComponent from "../../component/Helmet/Helmet";
import {Helmet} from "react-helmet";
import {BackLink, Page} from 'assets/styles/Main.styled';
import "./style.sass"

const ErrorPage = () => {
  return (
    <Page bgColor={"#F4f4f4"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Страница не найдена</title>
        <meta property="og:title" content="Страница не найдена"/>
        <meta name="robots" content="noindex" />
      </Helmet>
        <div className="container">
          <div className="row error-block align-items-center">
            <div className="col-lg-5 col-12 text-center">
              <img className="error-block__img" src={ErrorImg} alt=""/>
            </div>
            <div className="col-lg-7 col-12">
              <div className="error-block__info">
                <h1 className="error-block__title">Страница не найдена</h1>
                <p className="error-block__text">Но не волнуйтесь! Наш робот ROSBERGTRON
                  поможет вам ее отыскать.
                </p>
                <BackLink to="/">
                  Вернуться на сайт
                </BackLink>
              </div>
            </div>
          </div>
        </div>
    </Page>
  );
};

export default ErrorPage;
