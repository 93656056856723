import React, {FC} from 'react';
import {LendingTitle, LendingTitleArea, LendingTitleLink} from "./MainTitle.styled";

interface IMainTitle {
  title: string;
  link_title?: string;
  link_route?: string;
  mb: number;
  lr_mb: number;
  md_mb: number;
  sm_mb: number;
  esm_mb: number
}

const MainTitle: FC<IMainTitle> = ({link_title, link_route, title, lr_mb, md_mb, sm_mb, mb, esm_mb}) => {
  return (
    <LendingTitleArea
      mb={mb}
      lr_mb={lr_mb}
      md_mb={md_mb}
      sm_mb={sm_mb}
      esm_mb={esm_mb}
    >
      <LendingTitle>
        {title}
      </LendingTitle>
      {link_title &&
        <LendingTitleLink to={link_route ? link_route : "/"}>
          {link_title}
        </LendingTitleLink>
      }

    </LendingTitleArea>
  );
};

export default MainTitle;