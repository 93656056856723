import React, {memo} from 'react';
import {
  MobileMenuArea,
  MobileMenu,
  MobileMenuIcon,
  MobileMenuIconArrow,
  MobileMenuLi,
  MobileMenuLink,
  MobileMenuUl
} from "../Header.styled";
import {links} from "../header_helper";
import HeaderSearch from "./HeaderSearch";
import {IconSvg} from "../../../utils/shared";
import {useActions} from "../../../hooks/useActions";

const HeaderMobileMenu = () => {

  const {setIsOpenMobileMenu} = useActions()

  return (
    <MobileMenuArea>
      <MobileMenu>
        <MobileMenuUl>
          {links.map((link, index) => {
            if (link.visible) {
              return (
                <MobileMenuLi key={index} tabIndex={index + 1}>
                  <MobileMenuLink
                    onClick={()=> setIsOpenMobileMenu(false)}
                    to={link.route}>
                    <MobileMenuIcon>
                      {link.icon("#2F5379")}
                    </MobileMenuIcon>
                    <span>{link.title}</span>
                    <MobileMenuIconArrow>
                      {IconSvg.ui.arrow("#696B77")}
                    </MobileMenuIconArrow>
                  </MobileMenuLink>
                </MobileMenuLi>)
            }
          })}
        </MobileMenuUl>
        <HeaderSearch/>
      </MobileMenu>
    </MobileMenuArea>
  );
};

export default memo(HeaderMobileMenu);
